.defaultClass {
    color : black;
  }
  
  .errorMessage {
    font-size: 12px;
    font-weight: 400;
    color: #a80000;
    margin: 0;
    padding-top: 5px;
    display: flex;
    align-items: center;
  }
  
  .errorIcon {
    font-size: 14px;
    margin-right: 5px;
  }
  
  